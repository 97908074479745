import i18n from 'i18next';
import * as _ from 'lodash';
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';
import { Texture } from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes, FeatureState, FeatureTypes, GameMode, IBonus, bonusIds } from '../../global.d';
import {
  setBetAmount,
  setBonuses,
  setCoinAmount,
  setCurrency,
  setIsBuyFeaturePopupOpened,
  setIsHyperBattleBonus,
} from '../../gql/cache';
import { IBetSettings } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { ResourceTypes } from '../../resources.d';
import { formatNumber, normalizeCoins, showCurrency, updateTextScale } from '../../utils';
import { FeatureButton } from '../components/FeatureButton';
import { SpriteButtonState } from '../components/SpriteButton';
import {
  FEATURE_POPUP_AMOUNT_TEXT_POS_X,
  FEATURE_POPUP_AMOUNT_TEXT_POS_Y,
  FEATURE_POPUP_BASE_POS_X,
  FEATURE_POPUP_BASE_POS_Y,
  FEATURE_POPUP_BB_BTN_POS_X,
  FEATURE_POPUP_BB_BTN_POS_Y,
  FEATURE_POPUP_BB_COST_POS_X,
  FEATURE_POPUP_BB_COST_POS_Y,
  FEATURE_POPUP_BB_TITLE_POS_X,
  FEATURE_POPUP_BB_TITLE_POS_Y,
  FEATURE_POPUP_BET_VALUE_POS_X,
  FEATURE_POPUP_BET_VALUE_POS_Y,
  FEATURE_POPUP_CANCEL_BTN_POS_X,
  FEATURE_POPUP_CANCEL_BTN_POS_Y,
  FEATURE_POPUP_HBB_BTN_POS_X,
  FEATURE_POPUP_HBB_BTN_POS_Y,
  FEATURE_POPUP_HBB_COST_POS_X,
  FEATURE_POPUP_HBB_COST_POS_Y,
  FEATURE_POPUP_HBB_TITLE_POS_X,
  FEATURE_POPUP_HBB_TITLE_POS_Y,
  FEATURE_POPUP_INPUT_HEIGHT,
  FEATURE_POPUP_INPUT_POS_X,
  FEATURE_POPUP_INPUT_POS_Y,
  FEATURE_POPUP_INPUT_WIDTH,
  FEATURE_POPUP_MINUS_BTN_HEIGHT,
  FEATURE_POPUP_MINUS_BTN_POS_X,
  FEATURE_POPUP_MINUS_BTN_POS_Y,
  FEATURE_POPUP_MINUS_BTN_WIDTH,
  FEATURE_POPUP_OK_BTN_POS_X,
  FEATURE_POPUP_OK_BTN_POS_Y,
  FEATURE_POPUP_PLUS_BTN_HEIGHT,
  FEATURE_POPUP_PLUS_BTN_POS_X,
  FEATURE_POPUP_PLUS_BTN_POS_Y,
  FEATURE_POPUP_PLUS_BTN_WIDTH,
  FEATURE_POPUP_POS_X,
  FEATURE_POPUP_POS_Y,
  FEATURE_POPUP_TITLE_POS_X,
  FEATURE_POPUP_TITLE_POS_Y,
  Z_INDEX_BUY_FEATURE_POPUP,
  eventManager,
} from '../config';

import {
  BUY_FEATURE_BB_BTN,
  BUY_FEATURE_BTN_TITLE_TEXT_STYLE,
  BUY_FEATURE_COST_TEXT_STYLE,
  BUY_FEATURE_HBB_BTN,
  BtnType,
} from '.';
import {
  amountTextStyle,
  betValueStyle,
  buyFeatureBtnTitleDisableStyle,
  buyFeatureBtnTitleStyle,
  buyFeatureTitleStyle,
  totalCostTextAmountDisableStyle,
  totalCostTextAmountStyle,
} from './textStyles';

class BuyFeaturePopup extends PIXI.Container {
  private popupBg: PIXI.Sprite;

  private okBtn: PIXI.Sprite;

  private cancelBtn: PIXI.Sprite;

  private titleText: PIXI.Text;

  private titleBbText: PIXI.Text;

  private titleHbbText: PIXI.Text;

  // private btlBbBtn: PIXI.Sprite;

  // private btlHbbBtn: PIXI.Sprite;

  private btlBbBtn: FeatureButton;

  private btlHbbBtn: FeatureButton;

  private bbCostTextAmount: PIXI.Text;

  private hbbCostTextAmount: PIXI.Text;

  private amountText: PIXI.Text;

  private minusBtn: PIXI.Sprite;

  private plusBtn: PIXI.Sprite;

  private input: PIXI.Sprite;

  private betSettings: IBetSettings;

  private betAmount: number;

  private linesAmount: number;

  private currency = 'FUN';

  private betValue: PIXI.Text;

  // private bbBtn: FeatureButton;

  // private hBbBtn: FeatureButton;

  private featureState: FeatureState = FeatureState.BB;

  isNoFunds: boolean;

  balance: number;

  constructor(lines: number[][]) {
    super();
    this.betSettings = getBetsSetting();
    this.x = FEATURE_POPUP_BASE_POS_X;
    this.y = FEATURE_POPUP_BASE_POS_Y;

    this.visible = false;
    this.linesAmount = lines.length;
    this.balance = 0;
    this.isNoFunds = false;
    this.interactive = true;
    this.currency = setCurrency();

    this.btlBbBtn = this.initBaseModeBtn();
    this.btlHbbBtn = this.initRageModeBtn();

    this.betAmount = this.getBetAmount(setBetAmount());
    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();
    // this.btlBbBtn = this.initBbBnsBtn();
    // this.btlHbbBtn = this.initHbbBnsBtn();
    this.titleBbText = this.initBbTitle();
    this.titleHbbText = this.initHbbTitle();
    this.bbCostTextAmount = this.initBbCostTextAmount();
    this.hbbCostTextAmount = this.initHbbCostTextAmount();
    this.amountText = this.initAmountText();
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.input = this.initInput();
    this.betValue = this.initBetValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.zIndex = Z_INDEX_BUY_FEATURE_POPUP;

    this.init();
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.closeAllAnimationsInSlot();
      this.visible = true;
      this.btlBbBtn.interactive = true;
      this.btlHbbBtn.interactive = true;
      setIsBuyFeaturePopupOpened(true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_UPDATE_BTN);
    });
    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      this.visible = false;
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      setIsBuyFeaturePopupOpened(false);
    });
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance?: { currency: string; amount: number }) => {
      this.balance = balance!.amount / 100;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP_BG, () => {
      this.visible = true;
    });

    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.visible = false;
    });
  }

  private init(): void {
    this.addChild(
      this.popupBg,
      this.titleText,
      this.amountText,
      this.btlHbbBtn,
      this.btlBbBtn,
      this.titleBbText,
      this.titleHbbText,
      this.bbCostTextAmount,
      this.hbbCostTextAmount,
      this.minusBtn,
      this.plusBtn,
      this.input,
      this.betValue,
      this.okBtn,
      this.cancelBtn,
      // this.bbBtn,
      // this.hBbBtn,
    );
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePopup));

    popupBg.x = FEATURE_POPUP_POS_X;
    popupBg.y = FEATURE_POPUP_POS_Y;

    return popupBg;
  }

  private initTitle(): PIXI.Text {
    const title = new PIXI.Text(i18n.t<string>('buyBattle_buyBnsTitle'), buyFeatureTitleStyle);
    title.resolution = 1;
    title.y = FEATURE_POPUP_TITLE_POS_X;
    title.x = FEATURE_POPUP_TITLE_POS_Y;
    updateTextScale(title, this.popupBg.width - 200, 450);
    title.anchor.set(0.5, 0);
    return title;
  }

  private initBbTitle(): PIXI.Text {
    const title = new PIXI.Text(i18n.t<string>('buyBattle_buyBbTitle'), buyFeatureBtnTitleStyle);
    title.resolution = 1;
    title.x = FEATURE_POPUP_BB_TITLE_POS_X + this.btlBbBtn.texture.width / 2;
    title.y = FEATURE_POPUP_BB_TITLE_POS_Y;
    updateTextScale(title, this.btlBbBtn.width - 50, 450);
    title.anchor.set(0.5, 0);
    return title;
  }

  private initHbbTitle(): PIXI.Text {
    const title = new PIXI.Text(i18n.t<string>('buyBattle_buyHbbTitle'), buyFeatureBtnTitleDisableStyle);
    title.resolution = 1;
    title.x = FEATURE_POPUP_HBB_TITLE_POS_X + this.btlHbbBtn.texture.width / 2;
    title.y = FEATURE_POPUP_HBB_TITLE_POS_Y;
    updateTextScale(title, this.btlBbBtn.width - 50, 450);
    title.anchor.set(0.5, 0);
    return title;
  }

  private initBbCostTextAmount(): PIXI.Text {
    const totalCostTextAmount = new PIXI.Text(this.getBbTotalCost(), totalCostTextAmountStyle);
    totalCostTextAmount.resolution = 1;
    totalCostTextAmount.x = FEATURE_POPUP_BB_COST_POS_X + this.btlBbBtn.texture.width / 2; // 125 + this.btlBbBtn.texture.width / 2;
    totalCostTextAmount.y = FEATURE_POPUP_BB_COST_POS_Y; // 300;
    totalCostTextAmount.anchor.set(0.5);
    updateTextScale(totalCostTextAmount, this.btlBbBtn.width - 60, 300);

    return totalCostTextAmount;
  }

  private initHbbCostTextAmount(): PIXI.Text {
    const totalCostTextAmount = new PIXI.Text(this.getHbbTotalCost(), totalCostTextAmountDisableStyle);
    totalCostTextAmount.resolution = 1;
    totalCostTextAmount.x = FEATURE_POPUP_HBB_COST_POS_X + this.btlHbbBtn.texture.width / 2;
    totalCostTextAmount.y = FEATURE_POPUP_HBB_COST_POS_Y;
    totalCostTextAmount.anchor.set(0.5);
    updateTextScale(totalCostTextAmount, this.btlHbbBtn.width - 60, 300);
    return totalCostTextAmount;
  }

  private btnActive(btn: BtnType) {
    if (setIsHyperBattleBonus() && btn == BtnType.HbbBtn) {
      return;
    } else if (!setIsHyperBattleBonus() && btn == BtnType.BbBtn) {
      return;
    }
    setIsHyperBattleBonus(!setIsHyperBattleBonus());

    this.btlBbBtn.texture = PIXI.Texture.from(BUY_FEATURE_BB_BTN[setIsHyperBattleBonus() ? 1 : 0]);
    this.btlHbbBtn.texture = PIXI.Texture.from(BUY_FEATURE_HBB_BTN[setIsHyperBattleBonus() ? 0 : 1]);

    this.titleBbText.style = BUY_FEATURE_BTN_TITLE_TEXT_STYLE[setIsHyperBattleBonus() ? 1 : 0];
    this.bbCostTextAmount.style = BUY_FEATURE_COST_TEXT_STYLE[setIsHyperBattleBonus() ? 1 : 0];
    this.titleHbbText.style = BUY_FEATURE_BTN_TITLE_TEXT_STYLE[setIsHyperBattleBonus() ? 0 : 1];
    this.hbbCostTextAmount.style = BUY_FEATURE_COST_TEXT_STYLE[setIsHyperBattleBonus() ? 0 : 1];

    this.handleDisable();
  }

  private initBbBnsBtn() {
    let btnResource: ResourceTypes;
    if (!setIsHyperBattleBonus()) {
      btnResource = ResourceTypes.buyFeatureBbBtn;
    } else {
      btnResource = ResourceTypes.buyFeatureBbBtnNotActive;
    }
    const bbBtn = new PIXI.Sprite(PIXI.Texture.from(btnResource));
    bbBtn.x = FEATURE_POPUP_BB_BTN_POS_X;
    bbBtn.y = FEATURE_POPUP_BB_BTN_POS_Y;
    bbBtn.interactive = false;

    bbBtn.addListener('click', (e) => {
      this.btnActive(BtnType.BbBtn);
    });
    bbBtn.on('touchstart', (): void => this.btnActive(BtnType.BbBtn));

    bbBtn.addListener('mouseover', () => {
      if (setIsHyperBattleBonus() && this.visible) {
        AudioHowl.play({ type: ISongs.SFX_UI_Hover });
        this.btlBbBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBbBtnHover);
        this.titleBbText.style = BUY_FEATURE_BTN_TITLE_TEXT_STYLE[0];
        this.bbCostTextAmount.style = BUY_FEATURE_COST_TEXT_STYLE[0];
      }
    });
    bbBtn.addListener('mouseout', () => {
      if (setIsHyperBattleBonus() && this.visible) {
        this.btlBbBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBbBtnNotActive);
        this.titleBbText.style = BUY_FEATURE_BTN_TITLE_TEXT_STYLE[1];
        this.bbCostTextAmount.style = BUY_FEATURE_COST_TEXT_STYLE[1];
      }
    });
    bbBtn.addListener('mousedown', () => {
      if (setIsHyperBattleBonus() && this.visible) {
        this.btlBbBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBbBtnPressed);
      }
    });
    bbBtn.addListener('mouseup', () => {
      if (setIsHyperBattleBonus() && this.visible) {
        this.btlBbBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBbBtn);
      }
    });
    return bbBtn;
  }

  private initHbbBnsBtn() {
    let btnResource: ResourceTypes;
    if (setIsHyperBattleBonus()) {
      btnResource = ResourceTypes.buyFeatureHbbBtn;
    } else {
      btnResource = ResourceTypes.buyFeatureHbbBtnNotActive;
    }
    const hbbBtn = new PIXI.Sprite(PIXI.Texture.from(btnResource));
    hbbBtn.x = FEATURE_POPUP_HBB_BTN_POS_X;
    hbbBtn.y = FEATURE_POPUP_HBB_BTN_POS_Y;
    hbbBtn.interactive = false;

    hbbBtn.addListener('click', () => {
      this.btnActive(BtnType.HbbBtn);
    });
    hbbBtn.on('touchstart', (): void => this.btnActive(BtnType.HbbBtn));
    hbbBtn.addListener('mouseover', () => {
      if (!setIsHyperBattleBonus() && this.visible) {
        AudioHowl.play({ type: ISongs.SFX_UI_Hover });
        this.btlHbbBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureHbbBtnHover);
        this.titleHbbText.style = BUY_FEATURE_BTN_TITLE_TEXT_STYLE[0];
        this.hbbCostTextAmount.style = BUY_FEATURE_COST_TEXT_STYLE[0];
      }
    });
    hbbBtn.addListener('mouseout', () => {
      if (!setIsHyperBattleBonus() && this.visible) {
        this.btlHbbBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureHbbBtnNotActive);
        this.titleHbbText.style = BUY_FEATURE_BTN_TITLE_TEXT_STYLE[1];
        this.hbbCostTextAmount.style = BUY_FEATURE_COST_TEXT_STYLE[1];
      }
    });
    hbbBtn.addListener('mousedown', () => {
      if (!setIsHyperBattleBonus() && this.visible) {
        this.btlHbbBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureHbbBtnPressed);
      }
    });
    hbbBtn.addListener('mouseup', () => {
      if (!setIsHyperBattleBonus() && this.visible) {
        this.btlHbbBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureHbbBtn);
      }
    });
    return hbbBtn;
  }

  private initAmountText(): PIXI.Text {
    const amountText = new PIXI.Text(i18n.t<string>('BuyBattle_BetPerGame'), amountTextStyle);
    amountText.resolution = 1;
    amountText.y = FEATURE_POPUP_AMOUNT_TEXT_POS_Y;
    amountText.x = FEATURE_POPUP_AMOUNT_TEXT_POS_X;
    amountText.anchor.set(0.5, 0);

    updateTextScale(amountText, 450, 250);

    return amountText;
  }

  private initMinusBtn(): PIXI.Sprite {
    const minusBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnNotActive));
    minusBtn.width = FEATURE_POPUP_MINUS_BTN_WIDTH;
    minusBtn.height = FEATURE_POPUP_MINUS_BTN_HEIGHT;
    minusBtn.y = FEATURE_POPUP_MINUS_BTN_POS_Y;
    minusBtn.x = FEATURE_POPUP_MINUS_BTN_POS_X;
    minusBtn.interactive = true;
    minusBtn.on('click', this.handleMinus);
    minusBtn.on('touchstart', this.handleMinus);

    minusBtn.addListener('mouseover', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
        AudioHowl.play({ type: ISongs.SFX_UI_Hover });
        this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnHover);
      }
    });
    minusBtn.addListener('mouseout', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
        this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtn);
      }
    });
    minusBtn.addListener('mousedown', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
        this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnPressed);
      }
    });
    minusBtn.addListener('mouseup', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
        this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnHover);
      }
    });

    return minusBtn;
  }

  private initPlusBtn(): PIXI.Sprite {
    const plusBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnNotActive));
    plusBtn.width = FEATURE_POPUP_PLUS_BTN_WIDTH;
    plusBtn.height = FEATURE_POPUP_PLUS_BTN_HEIGHT;
    plusBtn.y = FEATURE_POPUP_PLUS_BTN_POS_Y;
    plusBtn.x = FEATURE_POPUP_PLUS_BTN_POS_X;
    plusBtn.interactive = true;
    plusBtn.on('click', this.handlePlus);
    plusBtn.on('touchstart', this.handlePlus);

    plusBtn.addListener('mouseover', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
        AudioHowl.play({ type: ISongs.SFX_UI_Hover });
        this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnHover);
      }
    });
    plusBtn.addListener('mouseout', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
        this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtn);
      }
    });
    plusBtn.addListener('mousedown', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
        this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnPressed);
      }
    });
    plusBtn.addListener('mouseup', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
        this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnHover);
      }
    });

    return plusBtn;
  }

  private initInput(): PIXI.Sprite {
    const input = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureInput));
    input.width = FEATURE_POPUP_INPUT_WIDTH;
    input.height = FEATURE_POPUP_INPUT_HEIGHT;
    input.y = FEATURE_POPUP_INPUT_POS_Y;
    input.x = FEATURE_POPUP_INPUT_POS_X;
    input.anchor.set(0.5, 0);

    return input;
  }

  private initBetValue(): PIXI.Text {
    const betValue = new PIXI.Text(
      `${formatNumber(this.currency, normalizeCoins(this.getBetValue()), showCurrency(this.currency))}`,
      betValueStyle,
    );
    betValue.resolution = 1;
    betValue.y = FEATURE_POPUP_BET_VALUE_POS_Y;
    betValue.x = FEATURE_POPUP_BET_VALUE_POS_X;
    betValue.anchor.set(0.5);
    updateTextScale(betValue, this.input.width - 30, 40);

    return betValue;
  }

  private initCancelBtn(): PIXI.Sprite {
    const cancelBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn));
    cancelBtn.y = FEATURE_POPUP_CANCEL_BTN_POS_Y;
    cancelBtn.x = FEATURE_POPUP_CANCEL_BTN_POS_X;
    cancelBtn.interactive = true;
    cancelBtn.on('click', () => this.onCancel());
    cancelBtn.on('touchstart', () => this.onCancel());

    cancelBtn.addListener('mouseover', () => {
      AudioHowl.play({ type: ISongs.SFX_UI_Hover });
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnHover);
    });
    cancelBtn.addListener('mouseout', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn);
    });
    cancelBtn.addListener('mousedown', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnPressed);
    });
    cancelBtn.addListener('mouseup', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn);
    });
    return cancelBtn;
  }

  private onCancel() {
    AudioHowl.play({ type: ISongs.SFX_UI_Close });
    this.visible = false;
    setIsBuyFeaturePopupOpened(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
  }

  private initOkBtn(): PIXI.Sprite {
    const okBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn));
    okBtn.y = FEATURE_POPUP_OK_BTN_POS_Y;
    okBtn.x = FEATURE_POPUP_OK_BTN_POS_X;
    okBtn.interactive = true;
    okBtn.on('click', (): void => this.handleClickOk());
    okBtn.on('touchstart', (): void => this.handleClickOk());

    okBtn.addListener('mouseover', () => {
      if (!this.isNoFunds) {
        AudioHowl.play({ type: ISongs.SFX_UI_Hover });
        this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnHover);
      }
    });
    okBtn.addListener('mouseout', () => {
      if (!this.isNoFunds) {
        this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn);
      }
    });
    okBtn.addListener('mousedown', () => {
      if (!this.isNoFunds) {
        this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnPressed);
      }
    });
    okBtn.addListener('mouseup', () => {
      if (!this.isNoFunds) {
        this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn);
      }
    });

    return okBtn;
  }

  private getBetAmount = (betAmount: number): number => {
    return (
      _.findIndex(this.betSettings!.bets, (bet) => {
        return bet === betAmount / (this.linesAmount * 2);
      }) + 1
    );
  };

  private handleMinus = (): void => {
    if (this.betAmount > 1 && this.betSettings.bets[this.betAmount - 1] > this.betSettings!.minBet) {
      // eslint-disable-next-line no-plusplus
      this.betAmount--;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * this.linesAmount * 2);
      AudioHowl.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private handlePlus = (): void => {
    if (this.betSettings.bets[this.betAmount - 1] < this.betSettings!.maxBet) {
      // eslint-disable-next-line no-plusplus
      this.betAmount++;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * this.linesAmount * 2);
      AudioHowl.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private updateBets(): void {
    this.bbCostTextAmount.text = this.getBbTotalCost();
    this.hbbCostTextAmount.text = this.getHbbTotalCost();
    updateTextScale(this.bbCostTextAmount, this.btlBbBtn.width - 60, 300);
    updateTextScale(this.hbbCostTextAmount, this.btlBbBtn.width - 60, 300);

    this.betValue.text = `${formatNumber(
      this.currency,
      normalizeCoins(this.getBetValue()),
      showCurrency(this.currency),
    )}`;
    updateTextScale(this.betValue, this.input.width - 30, 40);
  }

  private getBbTotalCost = (): string => {
    return `${formatNumber(
      this.currency,
      normalizeCoins(this.getBetValue() * this.getBbCoinAmount()),
      showCurrency(this.currency),
    )}`;
  };

  private getHbbTotalCost = (): string => {
    return `${formatNumber(
      this.currency,
      normalizeCoins(this.getBetValue() * this.getHbbCoinAmount()),
      showCurrency(this.currency),
    )}`;
  };

  private getBetValue = (): number => {
    return this.linesAmount * 2 * (this.betSettings!.bets[this.betAmount - 1] || 1);
  };

  // TODO
  private getBbCoinAmount = (): number => {
    const bonuses = setBonuses();
    const bonus = _.chain(bonuses)
      .filter(
        (bonus) =>
          bonus.type === FeatureTypes.SPECIAL_ROUND && bonus.id === bonusIds[GameMode.BUY_FEATURE_BATTLE_BONUS],
      )
      .get(0, {})
      .value() as IBonus;

    return bonus.coinAmount;
  };

  // TODO
  private getHbbCoinAmount = (): number => {
    const bonuses = setBonuses();
    const bonus = _.chain(bonuses)
      .filter(
        (bonus) =>
          bonus.type === FeatureTypes.SPECIAL_ROUND && bonus.id === bonusIds[GameMode.BUY_FEATURE_HYPER_BATTLE_BONUS],
      )
      .get(0, {})
      .value() as IBonus;

    return bonus.coinAmount;
  };

  private handleClickOk = (): void => {
    let totalCost: string;
    if (setIsHyperBattleBonus()) {
      totalCost = this.getHbbTotalCost();
    } else {
      totalCost = this.getBbTotalCost();
    }
    if (!this.isNoFunds) {
      AudioHowl.play({ type: ISongs.SFX_UI_General });
      eventManager.emit(
        EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP,
        !setIsHyperBattleBonus(),
        totalCost,
        this.betSettings.bets[this.betAmount],
      );
      this.visible = false;
    }
  };

  private handleDisable = (): void => {
    const bet = this.betSettings.bets[this.betAmount - 1];
    if (bet === this.betSettings!.minBet) {
      this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnNotActive);
    } else {
      this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtn);
    }
    if (bet === this.betSettings!.maxBet) {
      this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnNotActive);
    } else {
      this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtn);
    }

    let conAmount: number;
    if (setIsHyperBattleBonus()) {
      conAmount = this.getHbbCoinAmount();
    } else {
      conAmount = this.getBbCoinAmount();
    }

    this.isNoFunds = this.balance < normalizeCoins(this.getBetValue() * conAmount);
    if (this.isNoFunds) {
      this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnNotActive);
    } else {
      this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn);
    }
  };

  private closeAllAnimationsInSlot() {
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }

  private initBaseModeBtn(): FeatureButton {
    const baseBtn = new FeatureButton({
      [SpriteButtonState.ACTIVE]: {
        texture: Texture.from(ResourceTypes.buyFeatureBbBtn), // buyFeatureBBBtnPressed
      },
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureBbBtnNotActive), // buyFeatureBBBtn
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureBbBtnHover), // buyFeatureBBBtnHover
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureBbBtnPressed), // buyFeatureBBBtnPressed
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureBbBtnNotActive), // buyFeatureBBBtnNotActive
      },
      // onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onHover: this.handleBaseBtnHover.bind(this),
      onClick: this.handleBaseBtnClick.bind(this),
      onTouchStart: this.handleBaseBtnClick.bind(this),
      onMouseOut: this.handleBaseBtnMouseOut.bind(this),
      isActive: true,
    });
    baseBtn.y = FEATURE_POPUP_BB_BTN_POS_Y;
    baseBtn.x = FEATURE_POPUP_BB_BTN_POS_X;

    return baseBtn;
  }

  private initRageModeBtn(): FeatureButton {
    const rageBtn = new FeatureButton({
      [SpriteButtonState.ACTIVE]: {
        texture: Texture.from(ResourceTypes.buyFeatureHbbBtn), // buyFeatureHBBBtnPressed
      },
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureHbbBtnNotActive), // buyFeatureHBBBtn
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureHbbBtnHover), // buyFeatureHBBBtnHover
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureHbbBtnPressed), // buyFeatureHBBBtnPressed
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureHbbBtnNotActive), // buyFeatureHBBBtnNotActive),
      },
      // onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onHover: this.handleRageBtnHover.bind(this),
      onClick: this.handleRageBtnClick.bind(this),
      onTouchStart: this.handleRageBtnClick.bind(this),
      onMouseOut: this.handleRageBtnMouseOut.bind(this),
    });
    rageBtn.y = FEATURE_POPUP_HBB_BTN_POS_Y;
    rageBtn.x = FEATURE_POPUP_HBB_BTN_POS_X;

    return rageBtn;
  }

  private handleBaseBtnMouseOut(): void {
    this.titleBbText.style = BUY_FEATURE_BTN_TITLE_TEXT_STYLE[1];
    this.bbCostTextAmount.style = BUY_FEATURE_COST_TEXT_STYLE[1];
    AudioHowl.play({ type: ISongs.SFX_UI_Hover });
  }

  private handleRageBtnMouseOut(): void {
    this.titleHbbText.style = BUY_FEATURE_BTN_TITLE_TEXT_STYLE[1];
    this.hbbCostTextAmount.style = BUY_FEATURE_COST_TEXT_STYLE[1];
    AudioHowl.play({ type: ISongs.SFX_UI_Hover });
  }

  private handleBaseBtnHover(): void {
    this.titleBbText.style = BUY_FEATURE_BTN_TITLE_TEXT_STYLE[0];
    this.bbCostTextAmount.style = BUY_FEATURE_COST_TEXT_STYLE[0];
    AudioHowl.play({ type: ISongs.SFX_UI_Hover });
  }

  private handleRageBtnHover(): void {
    this.titleHbbText.style = BUY_FEATURE_BTN_TITLE_TEXT_STYLE[0];
    this.hbbCostTextAmount.style = BUY_FEATURE_COST_TEXT_STYLE[0];
    AudioHowl.play({ type: ISongs.SFX_UI_Hover });
  }

  private handleBaseBtnClick(): void {
    this.changeFeatureState(FeatureState.BB);
    this.btnActive(BtnType.BbBtn);
    setIsHyperBattleBonus(false);
    this.btlHbbBtn.turnOff();
    this.btlBbBtn.turnOn();
    this.updateBets();
  }

  private handleRageBtnClick(): void {
    this.changeFeatureState(FeatureState.HBB);
    this.btnActive(BtnType.HbbBtn);
    setIsHyperBattleBonus(true);
    this.btlHbbBtn.turnOn();
    this.btlBbBtn.turnOff();
    this.updateBets();
  }

  private changeFeatureState(state: FeatureState): void {
    this.featureState = state;
    AudioHowl.play({ type: ISongs.SFX_UI_General });
    this.handleDisable();
  }

  override updateTransform(): void {
    super.updateTransform();
  }
}

export default BuyFeaturePopup;
