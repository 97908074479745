import * as _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes, GameMode, ModalOpeningTypes } from '../../global.d';
import {
  setBrokenGame,
  setIsAutoSpins,
  setIsLeftHandMode,
  setIsModalOpeningInProgress,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
  setIsSlotBusy,
  setSlotMachineInitialized,
} from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { isBattleBonusMode, isMobileDevice, updateTextScale } from '../../utils';
import { BgSkin } from '../background/background';
import { BACKGROUND_SIZE_HEIGHT, BACKGROUND_SIZE_WIDTH } from '../background/config';
import {
  FEATURE_BTN_LANDSCAPE_HEIGHT,
  FEATURE_BTN_LANDSCAPE_WIDTH,
  FEATURE_BTN_PORTRAIT_HEIGHT,
  FEATURE_BTN_PORTRAIT_WIDTH,
  FEATURE_BTN_TEXT_PRESS_X_OFFSET,
  FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
  FEATURE_BTN_TEXT_X_OFFSET,
  FEATURE_BTN_TEXT_Y_OFFSET,
  SAFE_AREA_LANDSCAPE_HEIGHT,
  SAFE_AREA_LANDSCAPE_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  Z_INDEX_BUY_FEATURE_BTN,
  buyFeatureClickTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureHoverTextStyle,
  buyFeatureTextStyle,
  eventManager,
} from '../config';

class BuyFeatureBtn extends PIXI.Container {
  private btn: PIXI.Sprite;

  private text: PIXI.Text;

  isDisabled: boolean;

  isLeftHandMode: boolean;

  private isFreeRoundBonus: boolean;

  private windowWidth: number;

  private windowHeight: number;

  constructor() {
    super();
    this.setPosition(SAFE_AREA_LANDSCAPE_WIDTH, SAFE_AREA_LANDSCAPE_HEIGHT);
    this.isDisabled = false;
    this.visible = !setBrokenGame();
    this.btn = this.initBuyFeatureBtn();
    this.isLeftHandMode = setIsLeftHandMode();
    this.isFreeRoundBonus = false;
    this.addChild(this.btn);

    this.text = this.initBuyFeatureText();
    this.addChild(this.text);

    this.windowWidth = BACKGROUND_SIZE_WIDTH;
    this.windowHeight = BACKGROUND_SIZE_HEIGHT;

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      if (!setIsSlotBusy()) {
        this.handleDisable(disable);
      }
    });
    // eventManager.on(
    //   EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME,
    //   (isVisible: boolean) => (this.visible = !isVisible),
    // );

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, this.handleLeftHandMode.bind(this));
    eventManager.on(EventTypes.SET_IS_FREE_ROUND_BONUS, (isFreeRoundBonus: boolean) => {
      this.isFreeRoundBonus = isFreeRoundBonus;
      this.handleDisable(isFreeRoundBonus);
    });
    this.zIndex = Z_INDEX_BUY_FEATURE_BTN;
  }

  private initBuyFeatureBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureBtn));
    btn.x = -250;
    btn.y = 200;
    btn.interactive = true;
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', () => {
      if (!this.isDisabled) {
        AudioHowl.play({ type: ISongs.SFX_UI_Hover });
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnHover);
        this.text.style = buyFeatureHoverTextStyle;
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
        this.text.style = buyFeatureTextStyle;
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnPressed);
        this.text.style = buyFeatureClickTextStyle;
        this.text.anchor.set(0.5, 0.5);
        this.text.position.set(FEATURE_BTN_TEXT_PRESS_X_OFFSET, FEATURE_BTN_TEXT_PRESS_Y_OFFSET);
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
        this.text.style = buyFeatureTextStyle;
      }
    });

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    return btn;
  }

  private initBuyFeatureText(): PIXI.Text {
    const text = new PIXI.Text(i18n.t<string>('buyFeatureBtnText'), buyFeatureTextStyle);
    text.resolution = 1;
    text.anchor.set(0.5, 0.5);
    text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
    updateTextScale(text, isMobileDevice() ? 120 : 120, isMobileDevice() ? 150 : 100);

    return text;
  }

  private onClick(): void {
    if (
      !this.isDisabled &&
      setIsModalOpeningInProgress() === ModalOpeningTypes.NONE &&
      !setIsAutoSpins() &&
      setSlotMachineInitialized()
    ) {
      setIsOpenAutoplayModal(false);
      setIsOpenBetSettingsModal(false);
      setIsOpenMenuModal({ isOpen: false, type: 'menu' });
      AudioHowl.play({ type: ISongs.BuyButton });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
      setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(disable: boolean): void {
    if (this.isFreeRoundBonus && !disable) return;
    if (this.isAutoSpinInProgress) return;

    this.isDisabled = disable;
    if (disable) {
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnNotActive);
      this.text.style = buyFeatureDisableTextStyle;
      this.text.anchor.set(0.5, 0.5);
      this.text.position.set(FEATURE_BTN_TEXT_PRESS_X_OFFSET, FEATURE_BTN_TEXT_PRESS_Y_OFFSET);
    } else {
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
      this.text.style = buyFeatureTextStyle;
      this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  private resize(width: number, height: number): void {
    this.windowWidth = width;
    this.windowHeight = height;

    // // TODO
    if (width > height) {
      this.btn.width = FEATURE_BTN_LANDSCAPE_WIDTH;
      this.btn.height = FEATURE_BTN_LANDSCAPE_HEIGHT;
    } else {
      this.btn.width = FEATURE_BTN_PORTRAIT_WIDTH;
      this.btn.height = FEATURE_BTN_PORTRAIT_HEIGHT;

      this.x = 0;
      this.y = SLOTS_CONTAINER_HEIGHT + FEATURE_BTN_LANDSCAPE_HEIGHT / 2;
    }

    this.setPosition(width, height);

    this.handleDisable(this.isDisabled);
  }

  private setPosition(width: number, height: number): void {
    // // TODO
    if (width > height) {
      this.scale.set(1.0);
      if (setIsLeftHandMode() && isMobileDevice()) {
        this.x = 1570;
      } else {
        this.x = 0;
      }
      this.y = 0;
    } else {
      this.scale.set(1.3);
      if (isMobileDevice()) {
        if (setIsLeftHandMode()) {
          this.x = 1300;
        } else {
          this.x = 450;
        }
      } else {
        this.x = 450;
      }
      this.y = 550;
    }
  }

  private handleLeftHandMode = (isLhm: boolean): void => {
    this.setPosition(this.windowWidth, this.windowHeight);
  };

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    if (isBattleBonusMode(settings.mode)) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }
}

export default BuyFeatureBtn;
